import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/keen-slider@6.8.6/node_modules/keen-slider/keen-slider.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_rhbfo4ecg2agqbusrff4lsuilq/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_rhbfo4ecg2agqbusrff4lsuilq/node_modules/next/font/google/target.css?{\"path\":\"src/assets/styles/fonts/index.ts\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\",\"vietnamese\",\"latin-ext\"],\"display\":\"swap\",\"style\":\"normal\"}],\"variableName\":\"mainFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_rhbfo4ecg2agqbusrff4lsuilq/node_modules/next/font/google/target.css?{\"path\":\"src/assets/styles/fonts/index.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\",\"vietnamese\",\"latin-ext\"],\"display\":\"swap\",\"style\":\"normal\"}],\"variableName\":\"backupFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_rhbfo4ecg2agqbusrff4lsuilq/node_modules/next/font/local/target.css?{\"path\":\"src/assets/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"src\":[{\"path\":\"VTFRedzone-ClassicOblique.ttf\"},{\"path\":\"VTFRedzone-ClassicOblique.woff\"},{\"path\":\"VTFRedzone-ClassicOblique.woff2\"}]}],\"variableName\":\"subFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_rhbfo4ecg2agqbusrff4lsuilq/node_modules/next/font/local/target.css?{\"path\":\"src/assets/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"src\":[{\"path\":\"PresicavHv-Regular.woff2\"},{\"path\":\"PresicavHv-Regular.woff\"},{\"path\":\"PresicavHv-Regular.ttf\"}]}],\"variableName\":\"PresicavHv\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_rhbfo4ecg2agqbusrff4lsuilq/node_modules/next/font/local/target.css?{\"path\":\"src/assets/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"src\":[{\"path\":\"PresicavLt-Regular.woff2\"},{\"path\":\"PresicavLt-Regular.woff\"},{\"path\":\"PresicavLt-Regular.ttf\"}]}],\"variableName\":\"PresicavLt\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_rhbfo4ecg2agqbusrff4lsuilq/node_modules/next/font/local/target.css?{\"path\":\"src/assets/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"src\":[{\"path\":\"MabryPro-Regular.ttf\"},{\"path\":\"MabryPro-Regular.woff\"},{\"path\":\"MabryPro-Regular.woff2\"}]}],\"variableName\":\"MabryPro\"}");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styles/root.css");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styles/sr-widget.css");
